<template>
	<div class="selfMenuPage" v-loading="loading">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
			<div class="selfTitle">自助菜单</div>
			<div class="set-nemubox">
				<div v-for="(item,index) in ruleForm.menuArry" :key="index">
					<!-- 默认菜单 -->
					<div class="addSelfMenu" v-if="item.Type=='0'">
						<div style="position: relative;">
							<span @click="againSelect(item,index)">
								<span>菜单: <span class="menunames" style="margin-left:10px">{{item.coupName}}</span></span>
								<span class="menunames"></span>
								<span class="menunames el-icon-arrow-down" style="margin-left: 10px;"></span>
							</span>
							<div class="delectBtn" @click="delectMenus(index)">删除</div>
						</div>
						<div style="margin-top:15px">
							<el-form-item label="标题:" :prop="'menuArry.' + index + '.Title'" :rules='rules.Title'>
								<input v-model="item.Title" placeholder="最多输入18字" maxlength="18" style="padding:0 10px;width:300px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;">
							</el-form-item>
						</div>
					</div>

					<!-- 优惠券 -->
					<div class="addSelfMenu" v-if="item.Type=='1'">
						<div style="position: relative;">
							<span @click="againSelect(item,index)">
								<span>菜单:<span class="menunames" style="margin-left:10px">{{item.coupName}}</span></span>
								<span class="menunames"></span>
								<span class="menunames el-icon-arrow-down" style="margin-left: 10px;"></span>
							</span>
							<p style="color:rgba(144, 147, 153, 1);font-size: 12px;margin-top:15px">若优惠券设置领取次数限制，客户不满足领取条件时将不再发放；若优惠券剩余数量为0或少于赠送数量，将不再发放。请关注优惠券剩余数量并及时补充</p>
							<div class="delectBtn" @click="delectMenus(index)">删除</div>
						</div>
						<div style="margin:15px 0 0 55px">
							<el-card class="box-card menu-infors" v-for="(record,index1) in item.ActivityCouponList" :key="index1">
								<p>{{record.CouponName}}</p>
								<p class="discount-content">{{record.CouponContent}}</p>
								<div class="el-icon-error discount-delect" @click="delectCoupon(index,index1)"></div>
							</el-card>
						</div>
						<div class="adddicounts" @click="addDiscountFunc(index,item)" v-if=" item.ActivityCouponList.length<4">
							<span class="el-icon-plus"></span>
							<span>添加优惠卷</span>
						</div>

						<div style="margin-top:15px">
							<el-form-item label="标题:" :prop="'menuArry.' + index + '.Title'" :rules='rules.Title'>
								<input v-model="item.Title" maxlength="18" style="padding:0 10px;width:500px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;">

							</el-form-item>
						</div>

					</div>
				</div>

				<div class="addSelfMenu menunames" @click="addSelffun" v-if="ruleForm.menuArry.length<8">
					<span class="el-icon-plus"></span>
					<span>添加自助菜单</span>
				</div>

			</div>

			<div class="btnSave-box">
				<el-button type="primary" style="width: 150px;" @click="submitForm('ruleForm')" :loading="loading">保存</el-button>
			</div>
		</el-form>


		<!-- 选择菜单 -->
		<select-menu :visible="visibleShow" v-if="visibleShow" @getmenusData="getmenusData" :againData="againData"></select-menu>

	</div>
</template>

<script>
	import selectMenu from './component/selectMenu'
	import {
		chatCustomerMenuList,
		chatCustomerMenuSave,
	} from '@/api/goods'
	export default {
		components: {
			selectMenu
		},
		data() {
			return {
				visibleShow: false,
				ruleForm: {
					menuArry: [],
				},
				rules: {
					Title: {
						required: true,
						message: '请输入菜单标题',
						trigger: 'blur'
					},
				},
				againData: {},
				loading: false,


			}
		},
		beforeMount() {
			this.getDatas()
		},
		methods: {
			// 获取自助菜单列表
			async getDatas() {
				try {
					this.loading = true;
					let result = await chatCustomerMenuList()
					this.ruleForm.menuArry = result.Result
					this.ruleForm.menuArry.map(item => {
						item.coupName = item.CoupName
					})

				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}
			},

			// 保存
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {					
					this.ruleForm.menuArry.map(record => {
						if (record.ActivityCouponList&&record.ActivityCouponList.length && (record.Type == 1)) {
							record.ActivityCouponIds = []
							record.ActivityCouponList.map(item => {
								record.ActivityCouponIds.push(item.Id)
							})
						}
					})
					if (valid) {
						try {
							this.loading = true
							let data = {
								ChatCustomerMenus: this.ruleForm.menuArry
							}
							let result = await chatCustomerMenuSave(data)
							if (result.IsSuccess) {
								this.$message({showClose: true,
									type: 'success',
									message: '保存成功!'
								});
								this.getDatas()
							}

						} catch (e) {
							console.log(e)
							this.loading = false
						} finally {
							this.loading = false
						}

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 修改菜单
			againSelect(record, index) {
				if (!record.selecId) {
					record.selecId = record.PageName
				}

				this.againData = record;
				this.againData.isEdite = true
				this.againData.index = index
				this.visibleShow = true;
			},
			// 添加优惠券
			addDiscountFunc(index, item) {
				this.againData = item;
				this.againData.isEdite = true
				this.againData.index = index
				this.visibleShow = true;
			},
			// 获取选择的页面
			getmenusData(value) {
				this.visibleShow = value.visibleFun
				if (!value.isEdite) {
					if (value.activeNameFun == '1') {
						value.datas.activeNameFun = value.activeNameFun
						value.datas.coupName = value.datas.name
						value.datas.Title = value.datas.name
						value.datas.Type = 0
						value.datas.PageName = value.datas.selecId
						this.ruleForm.menuArry.push(value.datas);
					}
					if (value.activeNameFun == '2') {

						let obj = {
							Title: '优惠券',
							coupName: '优惠券',
							activeNameFun: value.activeNameFun,
							ActivityCouponList: [],
							Type: 1,

						}
						value.datas.map(item => {
							obj.ActivityCouponList.push(item)
						})

						this.ruleForm.menuArry.push(obj);
					}
				} else {
					if (value.activeNameFun == '1') {

						let obj = {
							Id:value.Id,
							activeNameFun: value.activeNameFun,
							coupName: value.datas.name,
							PageName: value.datas.selecId,
							index: value.index,
							isEdite: value.isEdite,
							Title: value.datas.name,
							Type: 0,
							selecId: value.datas.selecId

						}
						this.ruleForm.menuArry[value.index] = obj;

					}
					if (value.activeNameFun == '2') {
						let obj1 = {
							activeNameFun: value.activeNameFun,
							ActivityCouponList: value.datas,
							coupName: "优惠券",
							index: value.index,
							isEdite: value.isEdite,
							Title: "优惠券",
							Type: 1,
							Id:value.Id
						}
						this.ruleForm.menuArry[value.index] = obj1
					}
					this.$forceUpdate();
				}
				console.log(this.ruleForm.menuArry, '菜单')

			},
			// 删除菜单
			delectMenus(index) {
				this.$confirm('是否确认删除这个自助菜单，删除后不可恢复?', '提示', {
					confirmButtonText: '确认删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async () => {
					this.ruleForm.menuArry.splice(index, 1)
				}).catch(() => {
					this.$message({showClose: true,
						type: 'info',
						message: '已取消删除'
					});
				}).finally(() => {})
			},
			// 删除优惠券
			delectCoupon(index, indexs) {
				this.ruleForm.menuArry[index].ActivityCouponList.splice(indexs, 1)
				this.$forceUpdate()
			},
			// 添加自助菜单
			addSelffun() {
				this.againData = {}
				this.visibleShow = true;
			},

		}
	}
</script>

<style lang="less" scoped>
	.selfMenuPage {
		padding: 15px;
		background: #fff;
		font-size: 14px;

		.selfTitle {
			padding: 15px 10px;
			border-bottom: 1px solid #ddd;
		}

		.addSelfMenu {
			width: 70%;
			padding: 13px 15px;
			background: #F5F5F5;
			margin-top: 30px;
			cursor: pointer;
		}

		.btnSave-box {
			position: fixed;
			width: 100%;
			bottom: 0;
			background: #fff;
			text-align: center;
			padding: 15px 0;
			box-shadow: 13px 1px 6px #999;
		}

		.set-nemubox {
			margin-bottom: 80px;

			.menunames {
				cursor: pointer;
				color: rgba(83, 168, 255, 1);
			}

			.delectBtn {
				cursor: pointer;
				color: red;
				position: absolute;
				top: 0;
				right: 15px;
			}
		}

		.menu-infors {
			width: 500px;
			position: relative;
			margin-bottom: 10px;

			p {
				color: rgb(16, 16, 16) 100%;
				font-size: 14px;
				word-break: break-all;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}

			.discount-content {
				color: #908e8e;
				font-size: 12px;
				margin-top: 15px;
			}

			.discount-delect {
				position: absolute;
				font-size: 22px;
				cursor: pointer;
				top: 0;
				right: 0;
			}
		}

		.adddicounts {
			color: rgba(83, 168, 255, 1);
			width: 500px;
			background: #fff;
			padding: 10px;
			font-size: 14px;
			margin-left: 55px;
		}
	}
</style>
