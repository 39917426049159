<template>
	<div class="selectMenu">
		<el-dialog title="选择菜单" :visible.sync="isShow" width="900px" @closed="closedFun">
			<el-tabs v-model="activeName" type="card" @tab-click="tabClick">
				<el-tab-pane label="默认菜单" name="1">
					<template>
						<el-table :data="selectData" style="width: 100%">
							<el-table-column prop="name" label="页面名称">
								<template slot-scope="scope">
									<el-radio v-model="selectRadio" :label="scope.row.selecId">{{scope.row.name}}</el-radio>
								</template>
							</el-table-column>
						</el-table>
					</template>

					<div style='text-align: center;margin-top:20px;cursor: pointer;'>
						<el-button type="primary" style='width:120px' @click="sureSlect">确定</el-button>
					</div>

				</el-tab-pane>

				<el-tab-pane label="优惠券" name="2">

					<div class="filter-container title-border">
						<!-- 关键字搜索 -->
						<div class="filter-item">
							<label class="label">关键字: </label>
							<el-input v-model="counpKeys" style="width: 200px;" placeholder="优惠券名称" clearable></el-input>
						</div>

						<div class="filter-item">
							<el-button type="primary" @click="coupFilter">查询</el-button>
						</div>

					</div>

					<div class="table-container content">
						<el-table :data="tableDataList" v-loading="loading" style="width: 100%" ref="compSelectProTable" :row-key="getRowKeys"
						 @selection-change="handleSelectionChange">
							<el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
							<el-table-column prop="CouponName" label="优惠券"></el-table-column>
							<el-table-column prop="UseTypeValue" label="类型"></el-table-column>
							<el-table-column prop="CouponContent" label="优惠内容"></el-table-column>
							<el-table-column prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
							<el-table-column prop="ReceiveLimitValue" label="限领次数"></el-table-column>
							<el-table-column prop="LeaveCount" label="剩余数量"></el-table-column>
						</el-table>

						<div style="text-align: right;margin-top:20px;">
							<el-pagination v-if="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
							 :page-sizes="[5,10, 15, 20, 25, 30]" :page-size="size" layout="total, sizes, prev, pager, next, jumper" :total="total">
							</el-pagination>
						</div>

						<div style="text-align: center;margin-top:20px;">
							<span style="margin:0 10px">已选中<span style="color:red;margin:0 5px">{{selectNumber}}</span>张</span>
							<el-button type="primary" @click="sureSelectcoup" :laoding="loading">确定</el-button>
						</div>

					</div>

				</el-tab-pane>
			</el-tabs>


		</el-dialog>
	</div>
</template>

<script>
	import {
		activitycoupoList,

	} from '@/api/goods'
	export default {
		props: {
			visible: {
				type: Boolean
			},
			againData: {
				type: Object
			}
		},

		data() {
			return {
				isShow: false,
				selectRadio: null,
				activeName: '1',
				selectData: [{
						name: '加入会员',
						selecId: 1
					},
					{
						name: '订单查询',
						selecId: 2
					},
					{
						name: '猜你想问',
						selecId: 3
					},
					{
						name: '物流查询',
						selecId: 4
					}
				],
				menusDatas: {},
				multipleSelection: [],
				tableDataList: [],
				total: 0,
				current: 1,
				size: 10,
				selectNumber: 0,
				loading: false,
				counpKeys: '',
				CouponList: []
			}
		},

		beforeMount() {
			if (this.againData.Type == '0') {
				this.activeName = '1'
				this.selectRadio = this.againData.selecId				

			} else if (this.againData.Type == '1') {
				this.activeName = '2'
				this.selectRadio = null
				this.CouponList = this.againData.ActivityCouponList
			}

			this.activitycoupoList()
			this.isShow = this.visible
		},
		methods: {
			tabClick() {
				this.activitycoupoList()
			},

			sureSlect() {
				this.selectData.map(item => {
					if (item.selecId == this.selectRadio) {
						this.menusDatas = item
					}
				})

				this.$emit('getmenusData', {
					datas: this.menusDatas,
					'visibleFun': false,
					activeNameFun: '1',
					isEdite: this.againData && this.againData.isEdite ? true : false,
					index: this.againData ? this.againData.index : null,
					Id:this.againData && (this.againData.Id>0)?this.againData.Id:0
				})

			},
			closedFun() {
				this.$emit('getmenusData', {
					'visibleFun': false,
				})
			},
			// 确定选中的优惠券
			sureSelectcoup() {

				if (!this.multipleSelection.length) {
					this.$message({showClose: true,
						type: 'error',
						message: '请选择优惠券'
					});
					return
				}

				if (this.multipleSelection.length > 4) {
					this.$message({showClose: true,
						type: 'error',
						message: '单个自助菜单,优惠券最多选择4张'
					});
					return
				}
				this.$emit('getmenusData', {
					datas: this.multipleSelection,
					visibleFun: false,
					activeNameFun: '2',
					isEdite: this.againData && this.againData.isEdite ? true : false,
					index: this.againData ? this.againData.index : null,
					Id:this.againData && (this.againData.Id>0)?this.againData.Id:0
				})
				
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.size = val;
				this.activitycoupoList()
			},
			// 翻页
			handleCurrentChange(val) {
				this.current = val;
				this.activitycoupoList()
			},

			//当前页全选
			handleSelectionChange(val) {
				this.selectNumber = val.length
				this.multipleSelection = val;

			},
			getRowKeys(row) {
				return row.Id;
			},
			// 优惠券查询
			coupFilter() {
				this.current = 1;
				this.activitycoupoList()
			},
			// 获取优惠券列表
			async activitycoupoList() {
				try {
					this.loading = true
					let data = {
						KeyWord: this.counpKeys,
						UseType: this.UseType,
						Skip: (this.current - 1) * this.size, // 略过数据
						Take: this.size, // 取的数据
					}

					let result = await activitycoupoList(data)
					this.tableDataList = result.Result.Results
					this.total = result.Result.Total


				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false

					if (this.CouponList.length) {
						this.$refs.compSelectProTable.clearSelection();
						this.CouponList.map(item => {
							this.$refs['compSelectProTable'].toggleRowSelection(item, true);
						})
					}

				}

			},

		}
	}
</script>

<style lang="less" scoped>

</style>
